<template>
  <div class="page">
    <div class="search-box section">
      <el-form
          ref="searchForm"
          :inline="true"
          :model="query"
          class="demo-form-inline"
          size="medium"
      >
        <el-form-item prop="parkingId">
          <SuggestParking v-model="query.parkingId"/>
        </el-form-item>
        <el-form-item prop="leaseTitle">
          <el-input
              v-model="query.leaseTitle"
              placeholder="搜索套餐名称"
          ></el-input>
        </el-form-item>
        <el-form-item prop="leaseStatus">
          <el-select v-model="query.leaseStatus" placeholder="请选择-套餐状态">
            <el-option label="启用" value="0"></el-option>
            <el-option label="停用" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="buyWay">
          <el-select v-model="query.buyWay" placeholder="请选择-购买方式">
            <el-option label="开放购买" value="0"></el-option>
            <el-option label="审核购买" value="1"></el-option>
            <el-option label="专享购买" value="2"></el-option>
            <el-option label="不开放购买" value="3"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="goSearch">搜索</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          共计<span class="total-size">{{ total }}</span
        >条信息
        </div>
        <div>
          <div class="op-extra">
            <icon-button
                icon="add-select"
                title="添加套餐"
                @click.native="goAdd"
            />
            <icon-button
                icon="share"
                title="导出"
                @click.native="exportData"
            />
          </div>
        </div>
      </div>
      <div>
        <el-table
            v-loading="isLoading"
            :data="tableData"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column label="套餐名称" prop="leaseTitle" width="220">
          </el-table-column>
          <el-table-column label="车场名称" prop="parkingName" width="220">
          </el-table-column>
          <el-table-column label="套餐类型" width="180">
            <template>单车场</template>
          </el-table-column>
          <el-table-column label="长租周期" prop="longLeasePeriod" width="180">
          </el-table-column>
          <el-table-column label="有效时段" width="180">
            <template slot-scope="scope">
              周一至周日 {{ scope.row.effectualTime === 1 ? "全天" : "" }}
              <span v-if="scope.row.effectualTime === 0"
              >{{ scope.row.beginTime }}~{{ scope.row.endTime }}</span
              >
            </template>
            <!-- <template>{{effectualTime}}</template> -->
          </el-table-column>
          <el-table-column label="购买方式" prop="buyWay" width="180">
          </el-table-column>
          <el-table-column
              label="购买车牌"
              prop="licensePlateColor"
              width="180"
          >
            <!-- <template slot-scope="scope"> {{scope.row.licensePlateColor.join(' ')}} </template> -->
          </el-table-column>
          <el-table-column label="套餐价格(元)" prop="leasePrice" width="180">
            <template slot-scope="scope">
              {{ parseFloat(scope.row.leasePrice).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="线上展示" prop="onlineShow" width="180">
          </el-table-column>
          <!-- <el-table-column prop="onlineStock" label="线上库存" width="180">
          </el-table-column>
          <el-table-column prop="onlineRenewalLease" label="线上续租" width="180">
          </el-table-column> -->
          <el-table-column label="状态" prop="leaseStatus">
            <template slot-scope="scope">
              <span>{{ scope.row.leaseStatus === 0 ? "启用" : "停用" }}</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              fixed="right"
              label="操作"
              width="180"
          >
            <template slot-scope="scope">
              <el-button
                  class="common-text"
                  size="small"
                  type="text"
                  @click="goDetail(scope.row)"
              >详情
              </el-button
              >
              <el-button
                  v-if="scope.row.leaseStatus === 0"
                  class="common-text"
                  size="small"
                  type="text"
                  @click="stopStatus(scope.row)"
              >停用
              </el-button
              >
              <el-button
                  v-if="scope.row.leaseStatus !== 0"
                  class="common-text"
                  size="small"
                  type="text"
                  @click="usingStatus(scope.row)"
              >启用
              </el-button
              >
              <!-- <el-button type="text" size="small" style="font-size: 14px" @click="delete(scope.row)">删除</el-button> -->
              <el-button
                  v-if="hasRights('001007001004')"
                  class="common-text"
                  size="small"
                  type="text"
                  @click="goEdit(scope.row)"
              >编辑
              </el-button
              >
              <!-- <template>
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">更多</span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>停用</el-dropdown-item>
                    <el-dropdown-item>删除</el-dropdown-item>
                    <el-dropdown-item>编辑</el-dropdown-item>
                    <el-dropdown-item>活动</el-dropdown-item>
                  </el-dropdown-menu>
              </el-dropdown>
              </template> -->
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination-box">
          <el-pagination
              :current-page="pageNo"
              :page-size="pageSize"
              :total="total"
              background
              layout="sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {disableOrStartUsingLeaseInfo, getLeaseInfoListForPage,} from "@/api/lease";
import SuggestParking from "@/components/SuggestParking";
// import { objectToQueryString } from '@/utils/utils';
import {doExport} from "@/api/common";
import Permission from "@/mixins/permission";

export default {
  name: "lease-manage",
  mixins: [Permission],
  data() {
    return {
      //查询条件列表
      query: {
        parkingId: "", //停车场名称
        leaseTitle: "", //套餐名称
        leaseStatus: "", //套餐状态
        buyWay: "", //购买方式
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      isLoading: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      pages: 0,
      //查询到的数据列表
      tableData: [],
      showButton: true, //根据当前状态判断显示启用或停用按钮
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
    };
  },
  components: {
    SuggestParking,
  },
  methods: {
    //添加长租套餐
    goAdd() {
      this.$router.push("/lease/addLease");
    },
    //查看长租套餐详情
    goDetail(e) {
      this.$router.push({
        path: "/lease/lease_detail",
        query: {
          id: e.id,
          parkingLotId: e.parkingLotId,
        },
      });
    },
    //编辑长租套餐
    goEdit(e) {
      this.$router.push({
        path: "/lease/addLease",
        query: {
          id: e.id,
        },
      });
    },
    //重置查询条件
    resetForm() {
      // this.query = {
      //   parkingId: "",
      // }
      this.$refs.searchForm.resetFields();
      this.goSearch();
    },
    //查询长租套餐列表
    goSearch() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.search();
    },
    search() {
      const parkingId = this.query.parkingId;
      const leaseTitle = this.query.leaseTitle;
      const leaseStatus = this.query.leaseStatus;
      const buyWay = this.query.buyWay;
      const pageNo = this.pageNo;
      const pageSize = this.pageSize;
      const isExport = this.query.isExport;
      const exportSize = this.query.exportSize;

      this.isLoading = true;
      getLeaseInfoListForPage(
          parkingId,
          pageSize,
          leaseStatus,
          leaseTitle,
          buyWay,
          pageNo,
          isExport,
          exportSize
      ).then((res) => {
        this.isLoading = false;
        if (res && res.code === 30 && res.result) {
          this.tableData = res.returnObject.records;
          this.total = res.returnObject.total;
          this.pages = res.returnObject.pages;
          this.form.exportSize = res.returnObject.total;
          this.tableData.forEach((item) => {
            const longLeasePeriodMap = {
              0: "月",
              1: "季度",
              2: "半年",
              3: "全年",
              4: "天",
            };
            item.longLeasePeriod = longLeasePeriodMap[item.longLeasePeriod];

            // const effectualTimeMap = {
            //   0: "全天",
            //   1: "分时段"
            // }
            // item.effectualTime = effectualTimeMap[item.effectualTime]

            const buyWayMap = {
              0: "开放购买",
              1: "审核购买",
              2: "专享购买",
              3: "不开放购买",
            };
            item.buyWay = buyWayMap[item.buyWay];

            const onlineShowMap = {
              0: "是",
              1: "否",
            };
            item.onlineShow = onlineShowMap[item.onlineShow];

            item.licensePlateColor = JSON.parse(item.licensePlateColor);
            // item.licensePlateColor = item.licensePlateColor.join(' ')
            for (var i = 0; i < item.licensePlateColor.length; i++) {
              if (item.licensePlateColor[i] === "0") {
                item.licensePlateColor[i] = "通用";
              } else if (item.licensePlateColor[i] === "01") {
                item.licensePlateColor[i] = "黄牌 ";
              } else if (item.licensePlateColor[i] === "02") {
                item.licensePlateColor[i] = "蓝牌 ";
              } else if (item.licensePlateColor[i] === "03") {
                item.licensePlateColor[i] = "黑牌 ";
              } else if (item.licensePlateColor[i] === "04") {
                item.licensePlateColor[i] = "新能源 ";
              }

              //   const licensePlateColorMap = {
              //   "0": "通用",
              //   "01": "黄牌",
              //   "02": "蓝牌",
              //   "03": "黑牌",
              //   "04": "新能源"
              //   }
              // item.licensePlateColor = licensePlateColorMap[item.licensePlateColor[i]]
            }
          });
        }
      });
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = {
        parkingId: this.query.parkingId,
        leaseTitle: this.query.leaseTitle,
        leaseStatus: this.query.leaseStatus,
        buyWay: this.query.buyWay,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        isExport: "1",
        exportSize: this.form.exportSize,
      };

      // window.open("http://172.16.69.129:81" + '/leaseController/getLeaseInfoListForPage?parkingId=' + parkingId + '&pageSize=' + pageSize + '&leaseStatus=' + leaseStatus + '&leaseTitle=' + leaseTitle + '&buyWay=' + buyWay + '&pageNo=' + pageNo + '&isExport=' + isExport + '&exportSize=' + exportSize);
      doExport(
          "/leaseController/getLeaseInfoListForPage",
          params,
          "长租套餐.xlsx"
      );
      loading.close();
      this.exportDialogFormVisible = false;

      // if (res && res.code === 30 && res.result) {
      //   loading.close();
      //   this.exportDialogFormVisible = false;
      // }
    },

    //停用套餐
    stopStatus(e) {
      const id = e.id;
      const status = "1";
      this.$confirm("此操作将停用套餐, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            disableOrStartUsingLeaseInfo(id, status).then((res) => {
              if (res && res.code === 30 && res.result) {
                this.$message.success("停用成功！");
                this.goSearch();
              } else {
                // try {
                //   this.$message.error(res.data);
                // } catch {
                //   this.$message.error(res.msg);
                // }
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消停用",
            });
          });
    },

    //启用套餐
    usingStatus(e) {
      const id = e.id;
      const status = "0";
      this.$confirm("此操作将启用套餐, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            disableOrStartUsingLeaseInfo(id, status).then((res) => {
              if (res && res.code === 30 && res.result) {
                this.$message.success("启用成功！");
                this.goSearch();
              } else {
                // try {
                //   this.$message.error(res.data);
                // } catch {
                //   this.$message.error(res.msg);
                // }
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消启用",
            });
          });
    },

    handleSizeChange(e) {
      this.pageSize = e;
      this.search();
    },
    handleCurrentChange(e) {
      this.pageNo = e;
      this.search();
    },
  },
  mounted() {
    this.goSearch();
  },
};
</script>

<style lang="less" scoped>
// .el-dropdown-link {
//   cursor: pointer;
//   color: #0768FD;
// }
</style>
